import { graphql } from "gatsby"
import Link from "gatsby-link"
import React from "react"
import Helmet from "react-helmet"
import { navItemsSr } from "../constants/navigation"
import {
  Button,
  H1,
  ImageWrapper,
  Img,
  ItemsContent,
  ItemsWrapper,
  LeftBox,
  P,
  StyledImage,
  WrapperHero,
} from "../styled/home"

import Layout from "../components/SubLayout"

class SrIndexPage extends React.Component {
  render() {
    const hero = this.props.data.remark.edges[0].node.frontmatter
    const rest = this.props.data.remark.edges.slice(1, 4)
    return (
      <>
        <Helmet
          title="Početna - Miia"
          meta={[
            { name: "description", content: "Sampleee" },
            { name: "keywords", content: "sampleee, something" },
          ]}
        />
        <Layout
          data={this.props.data}
          location={this.props.location}
          navItems={navItemsSr}
          content={() => (
            <WrapperHero>
              <LeftBox>
                <H1>{hero.title}</H1>
                <P>{hero.description}</P>
                <Link to="/proizvodi">
                  <Button>Pogledajte sve proizvode</Button>
                </Link>
              </LeftBox>
              <StyledImage fluid={this.props.data.dog.sharp.fluid} />
            </WrapperHero>
          )}
        >
          {rest.map((item, index) => (
            <ItemsWrapper key={item.node.frontmatter.title}>
              <ImageWrapper order={index % 2}>
                <Img fluid={item.node.frontmatter.image.sharp.fluid} />
              </ImageWrapper>
              <ItemsContent order={index % 1}>
                <h2>{item.node.frontmatter.title}</h2>
                <p>{item.node.frontmatter.description}</p>
              </ItemsContent>
            </ItemsWrapper>
          ))}
        </Layout>
      </>
    )
  }
}

export default SrIndexPage

export const pageQuery = graphql`
  query PageSrUsQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    dog: file(relativePath: { eq: "Hero.jpg" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 720, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mainlogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
    }
    remark: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/homesrpski/" } }
      sort: { fields: [frontmatter___sort], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            height
            width
            blob2d {
              publicURL
            }
            image {
              sharp: childImageSharp {
                fluid(maxWidth: 420, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
